@mixin delay($total: 5, $delay: .3, $prefix: '&.late') {
  @for $i from 0 through $total {
    #{$prefix}#{$i} {
      @include animation-delay(#{$i * $delay}s);
      @include transition-delay(#{$i * $delay}s);
    }
  }
}

.fadeIn {
  opacity: 0;

  &.on {
    @include animation(fadeIn 1s);
    @include animation-fill-mode(forwards);
    @include delay();

    @include keyframes(fadeIn) {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
  }
}

@include delay(5, .3, '.late');

.fadeInFromLeft {
  margin-left:-20%;
  opacity: 0;
  transition: all 0.8s ease-in-out;

  &.on {
    opacity: 1;
    margin-left:initial;
  }
}

.fadeInFromRight {
  margin-right:-20%;
  opacity: 0;
  transition: all 0.8s ease-in-out;

  &.on {
    opacity: 1;
    margin-right:initial;
  }
}
