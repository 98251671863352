#WhereCanISection {
  background-color:white;

  color:#097b7a;
  padding:70px 0 40px 0;

  .slider-copy {
    height: 200px !important;

    @media (max-width:$screen-xs-max) {
      height: auto !important;
    }
  }


  .title {
    line-height: 45px;
    display: block;
    font-size: 30px;
    @media (max-width:$screen-xs-max) {
      font-size: 22px;
    }
    clear: right;
    float: left;
    font-weight: bold;

  }

  .left-icon {
    margin-right:10px;
    margin-left:5px;
    width:45px;
    height:45px;
    float:left;
  }

  .content {
    padding-right:10px;

    @media (max-width:$screen-xs-max) {
      padding-right: 60px;
    }
    margin-left:60px;
    .sub-text {

      //max-width: 400px;
      max-width: 630px;
      display: block;
      margin-bottom: 10px;
      margin-top: 15px;
    }

    input[type="text"] {
      max-width:850px;
      background-color:#14a49d;
      color:white;
      border:3px solid #53d0aa;
      border-radius:5px;
      @include placeholder(#DDDDDD);
      padding:5px;

    }
  }

  .thin-slider {
    max-width:800px;
    margin:0 auto;
    margin-bottom:-12px;
  }

  .center-table {
    display:table;
    width:100%;
    height:100%;

    font-size:15px;

    >div {
      display: table-cell;
      height:100%;
      vertical-align: middle;
    }

    @media (max-width: $screen-xs-max) {
      text-align:center;
    }
  }

  .slick-next {
    opacity:0.8;
    display: block;
    position: absolute;
    right: -34px;
    top: 50%;

    margin-top:-17px;
    width:34px;
    height:34px;
    background-image: url(../images/slick-next-green.png);
    background-repeat: no-repeat;
    background-size:100% auto;

    background-color: transparent;
    border:none;
    outline:none;
  }

  .slick-prev {
    opacity:0.8;
    display: block;
    position: absolute;
    left: -34px;
    top: 50%;
    margin-top:-17px;


    width:34px;
    height:34px;
    background-image: url(../images/slick-prev-green.png);
    background-repeat: no-repeat;
    background-size:100% auto;

    background-color: transparent;
    border:none;
    outline:none;
  }

  .center-block {
    text-align:center;

  }

  .center-image-link {
    text-decoration: none;
    display: inline-block;
    margin: 0 auto;
    .img-responsive {
      max-height: 135px;
      max-width:90%;
      -webkit-user-drag: none;
      margin:0 auto;
      width: auto;
    }
  }

  .SubPagesSection {
    margin-top:70px;
    padding-top:25px;

    @include gradient-horizontal(#0dbab4,transparent,0%,150%);
  }
}

.firefox, .ie {
  #WhereCanISection .center-image-link .img-responsive {
    width: 100%;
  }
}

.noPLR {
  padding-left: 0;
  padding-right: 0;
}