@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "../../../node_modules/slick-carousel/slick/slick.scss";
//@import "../../../node_modules/react-slick/node_modules/slick-carousel/slick/slick.scss";

@import "partials/mixins";
@import "fonts";
@import "Pages/VoluntaryPrinciples";
@import "Pages/ContactUs";
@import "Pages/ThanksForConnectWithUs";
@import "Pages/SupportingOrganisations";
@import "Pages/AboutCanIShareIt";
@import "Pages/Resources";
@import "Pages/Editor";
@import "Components/App";
@import "Components/Collapsable";
@import "Components/Collapser";
@import "Components/Accordion";
@import "Components/Scroller";
@import "Components/Popup";
@import "Sections/Landing";
@import "Sections/Footer";
@import "Sections/Menu";
@import "Sections/CanI";
@import "Sections/WhereCanI";
@import "Sections/HowCanI";
@import "Effects";

body, html {
  font-family: 'rubrik', sans-serif;
  font-size: 14px;
  line-height: 1.428571429;
  color: #333333;
  background-color: #fff;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.link {
  cursor:pointer;
  text-decoration: none !important;
}

.blue-link {
  color: darken(#64edea, 10) !important;
  text-decoration: none !important;

  &:hover {
    color: darken(#64edea, 20) !important;
  }

}

.blue-text {
  color: #64edea;
  font-weight: bold;
  &.head {
    font-size: 17px;
  }
}

.white-text {
  color: #FFFFFF;
  font-weight: normal;
  &.head {
    font-size: 17px;
  }
}

ul {
  color: white;
  margin: 0;
  padding: 0;
  padding-left: 15px;
  margin-bottom: 15px;
}

ul.blue-list {
  color: darken(#64edea, 10) !important;
  font-weight: bold;

  &.part-1 {
    margin-bottom: 0
  }

  a {
    color: darken(#64edea, 10) !important;
    text-decoration: none !important;

    &:hover {
      color: darken(#64edea, 20) !important;
    }
  }
}

label {
  color: white;
  line-height: 30px;
  font-size: 16px;
}

.form-control {
  border: solid 2px #54ccab !important;
  border-radius: 3px;
  background-color: #24a69e;
  color: white;
  outline: none !important;
  height: 45px;
}

textarea {
  resize: vertical;
  min-height: 150px;
  max-height: 300px;
}

select {
  background-color: #54ccab !important;
  color: darken(#24a69e, 10) !important;
  font-weight: bold;
  max-width: 270px !important;
}

.error {
  margin-top: -11px;
  margin-bottom: 10px;
  font-size: 12px;
  color: lighten(red, 40);
  font-style: italic;
}

.waitBox {

  display: block;
  position: absolute;
  left: 0;

  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 5;

  .icon {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: table;
    margin: auto;
    width: 140px;
    height: 40px;
    border-radius: 5px;
    line-height: 40px;
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-align: center;

    background-color: rgba(0, 0, 0, 0.4);
  }

}

.ie, .firefox {
  .waitBox {
    height:100%;
    display:table;
    width:100%;
    .icon {
      position: relative;
      display: table-cell;
      vertical-align: middle;
    }
    //position: relative;
  }
}

.triangle-main {
  position: absolute;
  right: 0;
  margin-top: -10%;
  pointer-events: none;
  max-width: 75%;
  max-height: 329px;

  @media (max-width: 768px) {
    display: none;
  }
}

.triangle-sub {
  position: absolute;
  right: 0;
  margin-top: -9%;
  pointer-events: none;
}

.result-table {
  tbody {
    tr:nth-child(even) {
      background-color:rgba(255,255,255,0.2);
    }
  }
}

.platformResult {
  padding:4px 8px;
  margin:2px 2px;
  border:solid 1px #4DD0C8;
  border-radius:8px;
  background-color: #00B6B8;
  //display: inline-block;
  color: #F9EDED;
}

.beta {
  width: 100px;
  position: fixed;
  right: 0;
  top: 0;
}

.noPointer {
  cursor: default !important;
}

.text.gray {
  color:gray !important;
}

.button.gray {
  background-color: gray;
  background-image: none !important;
}