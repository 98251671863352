#ContactUs {
  position: relative;
  height: 100%;
  background-image:url(../images/can-i-bkg.jpg);
  background-size:cover;
  background-position: 50% 0;
  color:white;
  margin:0 auto;

  padding-top: 40px;

  .main-content {

    min-height: 625px;

    @media (min-width: $screen-sm-min) {
      padding-left: 60px;
    }

    @media (max-width: 1180px) {
      padding-right: 160px;
    }

    @media (max-width: $screen-xs) {
      padding-right: 30px;
    }

    .row {
      max-width: 85%;

      @media (max-width: 1420px) {
        max-width: 100%;
      }

      @media (max-width: $screen-md) {
        max-width: 95%;
      }
    }

    .btn-link {
      color: white !important;
      text-decoration: none !important;
      transition: all linear 0.3s;
      &:hover {
        color: #DDD !important;
      }
    }
  }

  .triangle-one {
    position: absolute;
    right: 0;
    top: 0;
    width: 400px;
    height: auto;
    transition: width 0.2s linear;

    @media (max-width: 1420px) {
      max-width: 20%;
    }

    @media (max-width: $screen-md) {
      max-width: 30%;
    }

    @media (max-width: $screen-xs) {
      max-width: 140px;
    }
  }

}

label {
  color: white;
  line-height: 30px;
  font-size: 16px;
}

.form-control {
  border: solid 2px #54ccab !important;
  border-radius: 3px;
  background-color: #24a69e;
  color: white;
  outline: none !important;
  height: 45px;
}

textarea {
  resize: vertical;
  min-height: 150px;
  max-height: 300px;
}

select {
  background-color: #54ccab !important;
  color: darken(#24a69e, 10) !important;
  font-weight: bold;
  max-width: 270px !important;
}

.error {
  margin-top: -11px;
  margin-bottom: 10px;
  font-size: 12px;
  color: lighten(red, 40);
  font-style: italic;
}

.waitBox {

  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 5;

  .icon {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: table;
    margin: auto;
    width: 140px;
    height: 40px;
    border-radius: 5px;
    line-height: 40px;
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-align: center;

    background-color: rgba(0, 0, 0, 0.4);
  }

}