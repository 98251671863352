#Resources {
  background-image: url("../images/can-i-bkg.jpg");
  background-position: 50% 0%;
  background-size: cover;
  width: 100%;
  height: 100%;
  padding-top:50px;

  color: white;

  .main-content {

    padding-bottom: 80px;

    @media (min-width: $screen-sm-min) {
      padding-left: 60px;
    }

    min-height: 600px;

    a {
      color: darken(#64edea, 10) !important;
      text-decoration: none !important;

      &:hover {
        color: darken(#64edea, 20) !important;
      }
    }

    .triangle-one {
      position:absolute;
      right:-170px;
      width:400px;
      height:auto;
      transition: width 0.2s linear;
      margin-top:-10px;


      @media (max-width:1201px) {
        width:350px;
        right:-15px;
      }

      @media (max-width: $screen-xs) {
        max-width: 100%;
      }
    }
  }
}