#LandingSection {
  background-image: url('../images/landing_bkg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  //position: relative;
  padding-top: 4%;
  padding-bottom: 15%;
  color: white;
  height:100%;
  width:100%;
  display:block;
  position: absolute;
  @media (max-width: $screen-xs-max) {
    h1 {
      font-size:1.8em;
    }

    p {
      line-height: 20px;
    }
  }

  .logo {
    max-height: 15%;
    margin-bottom:100px;
    padding-left:45px; // for align the left icons (other sections icons: zoom, eye,people)
    @media (max-width: $screen-xs-max) {
      //max-height: 12%;
      width: 50%;
      padding-left:0;
      margin-bottom:25px;
    }
  }

  .content {
    max-width:630px;
    margin-bottom:70px;
    font-size: 20px;
    line-height:25px;
    padding-left:45px; // for align the left icons (other sections icons: zoom, eye,people)
    @media (max-width: $screen-xs-max) {
      padding-left:0;
      font-size: 16px;
    }
  }

  .scroll-down {
    position: absolute;
    bottom: 30px;
    width: 60px;
    height: 60px;
    left: 50%;
    margin-left: -27px;//-30px;

    -webkit-animation: bounce 2s infinite; /* Chrome, Safari, Opera */
    animation: bounce 2s infinite;

    @media (max-width: $screen-xs-max) {
      display: none;
    }
  }

  @keyframes bounce {
    0% {
      margin-bottom:-10px;
    }

    50% {
      margin-bottom:10px;
    }
    100% {
      margin-bottom:-10px;
    }
  }

}

.ie {
  #LandingSection .logo {
    margin-left: 0;
  }
}