.scroller {
  padding:5px;
  border:solid 3px #58cfab;
  background: #239f98;

  .content {
    overflow-y: scroll;

    height:350px;
    padding:5px;
    padding-right:10px;

    &::-webkit-scrollbar {
      width: 15px;
    }

    &::-webkit-scrollbar-corner {
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: #1a8b8a;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #58cfab;
      padding: 0;
      margin: 4px;
      border: solid 3px #1a8b8a;
    }
  }
}