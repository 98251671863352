.accordion {

  $animationLength: 0.5s;

  width: 100%;
  margin-top: 5px;

  &:first-child {
    margin-top: 0;
  }

  .header {
    padding: 8px 16px;
    background-color: #d7e4e3;
    border-radius: 5px;
    cursor: pointer;
    color: #043a50;
    font-weight: bold;
    position: relative;

    .line {
      position: absolute;
      top: 18px;
      width: 10px;
      height: 3px;

      transition: all #{$animationLength/3} linear;

      &-1 {
        right: 20px;
        background-color: #043a50;
        transform-origin:left top;
        &.opened {
          transform: rotate(0deg) translateY(0px);
          right:18px;
        }

        &.closed {
          transform: rotate(45deg) translateY(-6px);
        }

      }

      &-2 {
        right: 10px;
        background-color: #043a50;
        transform-origin:right top;
        &.opened {
          transform: rotate(0deg) translateY(0px);
          right:12px;
        }
        &.closed {
          transform: rotate(-45deg) translateY(-6px);
        }
      }

    }
  }

  .content {
    background-color: #d7e4e3;
    border-radius: 5px;
    color: #043a50;
    margin-top: 5px;
    overflow: hidden !important;
    height:0;
    padding: 0 !important;

    p {
      color: #043a50 !important;
      padding: 12px 24px;
      line-height: 20px;
    }

  }
}
