.Collapser {

  .content {
    overflow: hidden;

    &.opened {
      height:auto;
    }

    &.closed {
      height:0;
    }
  }

}