#FooterSection {
  position: absolute;
  width: 100%;
  background-color: #3b3838;
  padding: 40px 0;

  .logo {
    max-height: 100px !important;
    margin-right: 45px; // for align the left icons (other sections icons: zoom, eye,people)
    @media (max-width: $screen-xs-max) {
      margin-right: 0;
    }
  }

  .link {
    font-size: 16px;
    padding: 4px 0;
    padding-left: 45px; // for align the left icons (other sections icons: zoom, eye,people)
    @media (max-width: $screen-xs-max) {
      padding-left: 0;
    }
    display: block;
    &.white {
      color: white !important;
      text-decoration: none !important;
      &:hover {
        color: #CCCCCC !important;
      }
    }

    &.gray {
      font-size: 12px;
      color: #CCCCCC !important;
      text-decoration: none !important;
      padding-bottom: 3px;
      &:hover {
        color: #AAAAAA !important;
      }
    }
  }

}