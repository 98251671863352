#HowCanISection {
  background: url(../images/can-i-bkg.jpg) center top;
  background-size: cover;
  color: white;
  position: relative;

  .triangle-main {
    bottom: -70px;
  }

  .overlay {
    padding-bottom: 30px;
    padding-top: 25px;
    @include gradient-horizontal(#0dbab4, transparent, 0%, 70%);
  }

  .title {
    line-height: 45px;
    display: block;
    font-size: 30px;
    @media (max-width: $screen-xs-max) {
      font-size: 22px;
    }
    clear: right;
    float: left;
    font-weight: bold;
  }

  .left-icon {
    margin-right: 10px;
    margin-left: 5px;
    width: 45px;
    height: 45px;
    float: left;
  }

  .title-content {
    padding-right: 10px;
    @media (max-width: $screen-xs-max) {
      padding-right: 60px;
    }
    margin-left: 60px;
    .sub-text {
      max-width: 450px;
      display: block;
      margin-bottom: 30px;
      margin-top: 15px;
    }

    input[type="text"] {
      max-width: 850px;
      background-color: #14a49d;
      color: white;
      border: 3px solid #53d0aa;
      border-radius: 5px;
      @include placeholder(#DDDDDD);
      padding: 5px;

    }
  }

  .CollapsableHeader {
    color: white;
    @include gradient-horizontal(transparent, #026d6b, -100%, 100%);
    cursor: pointer;
    font-size: 16px;
  }

  .CollapsableContent {
    color: white;
    @include gradient-horizontal(transparent, #026d6b, -100%, 100%);
  }

  .firstColHeader {
    height: 60px;
    padding-left: 60px;
    line-height: 60px;

    strong {
      display:inline-block;
      width:210px;
    }

    @media (max-width: $screen-xs-max) {
      padding-left: 10px;
    }
  }

  .firstColContent {
    padding-left: 60px;

    @media (max-width: $screen-xs-max) {
      padding-left: 10px;

    }
  }

  .Collapser {
    margin-bottom: 3px;

    .header {
      .icon {
        margin-left: 15px;
        width: 28px;
        height: 28px;
        @include transition(0.3s linear all);
      }

      &.opened {
        .icon {
          @include rotate(90deg);
        }
      }

      &.closed {
        .icon {
          @include rotate(0deg);
        }
      }
    }
    &:last-child {
      margin-bottom: 3px;
    }
  }

  .slick-track {
    @media (min-width: 769px) {
      padding-bottom: 100px;
    }
  }

  button.slick-next {
    display: block;
    position: absolute;
    top: 50%;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    width: 45px;
    height: 45px;
    overflow: hidden;
    right: 0;
    margin-left: -55px;
    background-size: 100% auto;
    background-image: url(../images/slick-next.png);
  }

  .slick-slide {
    max-height: 600px;
    margin-bottom:15px;
    overflow: auto;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    &::-webkit-scrollbar {
      width:0 !important;
    }
    padding-bottom:25px;
  }

  button.slick-prev {

    display: block;
    position: absolute;
    top: 50%;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    width: 45px;
    height: 45px;
    overflow: hidden;
    margin-left: -5px;
    left: 0;
    background-size: 100% auto;
    background-image: url(../images/slick-prev.png);
  }

  @media (min-width:$screen-sm-min) {
    .slick-slide {
      padding-left: 50px;
      padding-right: 50px;
      //top:10px;
    }
  }

  @media (max-width:$screen-xs-max) {
    .slick-slide {
      padding-left: 18px;
      padding-right: 20px;
      max-height: 350px;
      margin-bottom:15px;
      overflow: auto;
    }
    button.slick-prev {

      width: 25px;
      height: 74px;
      background-image: url(../images/slick-prev-mob.png);
      background-size: 100% auto;
      margin-left: -10px;
    }

    button.slick-next {

      width: 25px;
      height: 74px;
      background-image: url(../images/slick-next-mob.png);
      background-size: 100% auto;
      margin-right: -15px;
      @include opacity(0.9);
    }
  }
}

.firefox, .ie {
  #HowCanISection {
    .slick-slide {
      max-height: initial;
    }

    .slick-list {
      height: initial;
    }
  }
}