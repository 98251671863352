#MenuSection {
  padding-top: 30px;
  padding-bottom: 30px;

  .icon {
    max-height: 120px !important;
    margin-bottom: 30px;
    cursor: pointer;
  }

  .text {
    text-align: center;
    color: #008384;
    font-weight: bold;
    font-size: 14px;
    max-width: 250px;
    margin: 0 auto;
    min-height: 80px;
  }

  .button {
    border: none;
    padding: 5px;
    margin: 15px auto 30px auto;
    cursor: pointer;
    width: 170px;
    color: white !important;
    text-decoration: none !important;
    text-align: center;
    background-size: 200% 100%;

    @include gradient-horizontal-three-colors(#5eb991, #82abde, 50%, #5eb991);
    @include transition(all 300ms ease-in-out);

    &:hover {
      background-position: 100% 0;
    }
  }
}