//@font-face {
//  font-family: 'rubrik';
//  src:
//    url('../fonts/rubrik-semi-bold.otf') format('woff'),
//    url('../fonts/rubrik-semi-bold.svg') format('svg'),
//    url('../fonts/rubrik-semi-bold.ttf') format('ttf');
//}

@font-face {
  font-family: 'rubrik';
  src: url('../fonts/rubrik_semibold-webfont.eot');
  src: url('../fonts/rubrik_semibold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/rubrik_semibold-webfont.woff2') format('woff2'),
  url('../fonts/rubrik_semibold-webfont.woff') format('woff'),
  url('../fonts/rubrik_semibold-webfont.ttf') format('truetype'),
  url('../fonts/rubrik_semibold-webfont.svg#rubrik_semiboldregular') format('svg');
  font-weight: bold;
  font-style: normal;

}

@font-face {
  font-family: 'rubrik';
  src: url('../fonts/rubrik-webfont.eot');
  src: url('../fonts/rubrik-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/rubrik-webfont.woff2') format('woff2'),
  url('../fonts/rubrik-webfont.woff') format('woff'),
  url('../fonts/rubrik-webfont.ttf') format('truetype'),
  url('../fonts/rubrik-webfont.svg#rubrikregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

//@font-face {
//  font-family: 'rubrik';
//  src: url('rubrik_italic-webfont.eot');
//  src: url('rubrik_italic-webfont.eot?#iefix') format('embedded-opentype'),
//  url('rubrik_italic-webfont.woff2') format('woff2'),
//  url('rubrik_italic-webfont.woff') format('woff'),
//  url('rubrik_italic-webfont.ttf') format('truetype'),
//  url('rubrik_italic-webfont.svg#rubrik_italicregular') format('svg');
//  font-weight: normal;
//  font-style: italic;
//
//}
//
//@font-face {
//  font-family: 'rubrik';
//  src: url('rubrik_semibold_italic-webfont.eot');
//  src: url('rubrik_semibold_italic-webfont.eot?#iefix') format('embedded-opentype'),
//  url('rubrik_semibold_italic-webfont.woff2') format('woff2'),
//  url('rubrik_semibold_italic-webfont.woff') format('woff'),
//  url('rubrik_semibold_italic-webfont.ttf') format('truetype'),
//  url('rubrik_semibold_italic-webfont.svg#rubrik_semibold_italicregular') format('svg');
//  font-weight: bold;
//  font-style: italic;
//}