#SupportingOrganizations {

  background-image: url("../images/can-i-bkg.jpg");
  background-position: 50% 0%;
  background-size: cover;
  width: 100%;
  height: 100%;
  padding-top:50px;

  .main-content {
    @media (min-width: $screen-sm-min) {
      padding-left: 60px;
    }
    color:white;

    padding-bottom:50px;

    background-image: url("../images/blue-background.jpg");
    background-repeat: no-repeat;
    background-position: 50% 0%;
    background-size: cover;
    //padding-right: 335px;

    @media (max-width: 1420px) {
      //padding-right: 185px;
    }

    @media (max-width: $screen-xs) {
      padding-right: 30px;
    }
  }

  h1 {
    @media (max-width: $screen-xs) {
      width: 260px;
    }
  }

  h4 {
    line-height: 28px;
  }

  .organisation {
    padding: 8px 0;
  }

  .col-2 {
    @media (max-width: $screen-xs) {
      padding-right: 15px;
    }
  }

  .triangle-one {
    position: absolute;
    right: 0;
    top: 0;
    width: 400px;
    height: auto;
    transition: width 0.2s linear;

    @media (max-width: 1420px) {
      max-width: 20%;
    }

    @media (max-width: $screen-md) {
      max-width: 26%;
    }

    @media (max-width: $screen-sm) {
      max-width: 30%;
    }

    @media (max-width: $screen-xs) {
      max-width: 140px;
    }
  }
}