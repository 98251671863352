#ThanksForConnectWithUs {

  background-image:url(../images/can-i-bkg.jpg);
  background-size:cover;
  background-position: 50% 0;

  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  top: 0;

  .main-content {

    background-size: cover;

    .thanks-block {
      width:100%;
      position:absolute;
      display:table;
      margin:auto;
      left:0;
      top:0;
      right:0;
      bottom:0;

      font-size:25px;
      font-weight:bold;
      color:white;
      text-align:center;

      .goTo {

        color: lighten(#154445,40);
        cursor:pointer;
        text-transform: uppercase;
        transition:all 0.1s linear;
        &:hover {
          color: #154445;
        }
      }

    }
  }
}

.ie, .firefox {
  #ThanksForConnectWithUs {
    .main-content {
      height:100%;
      display:table;
      width:100%;

      .thanks-block {
        position: relative;
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
}