#CanISection {
  background-image:url(../images/can-i-bkg.jpg);
  background-size:cover;
  border-bottom:solid 1px white;

  color:white;
  padding:30px 0 30px;
  position: relative;

  .triangle-main {
    top: 0;
    z-index: 1;
  }

  .info-button {
    color: #a3e3d2 !important;
    cursor: pointer;
    font-size: 18px;
  }

  .title {
    line-height: 45px;
    display: block;
    font-size: 30px;
    @media (max-width:$screen-xs-max) {
      font-size: 22px;
    }
    clear: right;
    float: left;
    font-weight: bold;
  }

  .left-icon {
    margin-right:10px;
    margin-left:5px;
    width:45px;
    height:45px;
    float:left;
  }

  .content {
    padding-right:10px;
    @media (max-width:$screen-xs-max) {
      padding-right: 60px;
    }
    margin-left:60px;
    .sub-text {
      //max-width: 450px;
      display: block;
      margin-bottom: 30px;
      margin-top: 15px;
      width: 70%;

      @media (max-width:$screen-xs-max) {
        width: 80%;
      }

      @media (max-width:$screen-xs) {
        width: 100%;
      }
    }

    input[type="text"] {
      max-width:850px;
      background-color:#14a49d;
      color:white;
      border:3px solid #53d0aa;
      border-radius:5px;
      @include placeholder(#DDDDDD);
      padding:5px;

    }
  }

  .language__selector {
    display: inline-block;
    margin-top: 12px;
    margin-left: 15px;
  }

  .Popup .content {
    margin: 15px;
    @media (max-width:$screen-xs-max) {
      padding: 10px;
    }
  }

  .result {
    font-size:18px;
  }

  //background-image: url("../images/blue-background.jpg");
  //background-repeat: no-repeat;
  //background-position: 50% 0%;
  //background-size: cover;
  //height: 100%;

  .main-content {
    color:white;
  }

  button {
    background-color: #54CCAB !important;
    border-color: #58CFAB !important;
    &:hover {
      background-color:lighten(#54CCAB,20) !important;
    }
  }

  .list-group {
    padding-left:0;
    color:inherit;

    .list-group-item {
      background-color:transparent;
      border-color:transparent;
      padding:0 0 5px 0;
      border-top: solid 1px #3AB6B4;
      color:inherit;

      &:last-child {
        border-bottom: solid 1px #3AB6B4;
      }
    }

    .active {
      background-color:#388F90;
      border-left: solid 5px #388F90;
      border-radius:0;
    }
  }
}

.comingSoonOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(100, 100, 100, 0.8);
  text-transform: capitalize;
  z-index:0;

  .title {
    margin: auto;
    position: absolute;
    text-align: center;
    width: 100%;
    bottom: 0;
    top: calc(50% - 19px);
    left: 0;
    right: 0;

  }
}