.Popup {
  .grayback {
    position:fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    width: 100%;
    height: 100%;
    z-index:999;
    background-color:rgba(0,0,0,0.7);
  }
  text-align:center;

  .holder {
    position:fixed;
    top:10%;
    max-height:80%;
    padding:15px;
    border-radius:5px;
    border:solid 3px #58cfab;
    background: #239f98;
    overflow-y: auto;
    z-index:1000;
    color:white;

    @media (max-width:659px) {
      right:5%;
      width: 90%;
    }

    @media (min-width:660px) {
      width:610px;
      margin-left:-305px;
      left:50%;
    }
  }

  .closeButton {
    width:30px;
    height:30px;

    position:fixed;
    cursor:pointer;

    right:calc(5% - 10px);
    top:calc(10% - 15px);

    @media (min-width:660px) {
      right:50%;
      margin-right:-315px;
    }

    background-image:url('../images/close-popup.png');
    background-size:100% auto;
    background-repeat: no-repeat;

    z-index:1001;
  }
}